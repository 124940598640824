const types = {
  CORE: "Core",
  COMPANIES: "Company",
  CUSTOMERS: "Customers",
  PRODUCTS: "Products",
  INVOICES: "Invoices",
  BANKACCOUNT: "Bank Account",
  INSIGHTS: "Insights",
};
export default types;
